* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: normal;
}

html {
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Akkurat, sans-serif;
  height: 100vh;
}

textarea,
input[type='date'],
pre {
  font-family: inherit;
}
input[type='date'] {
  line-height: 1;
}

.scroll-lock {
  overflow: hidden;
}

/* CSS Transitions */

.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 200ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms;
}

.slide-enter {
  transform: translateX(-100%);
}
.slide-enter-active {
  transform: translateX(0);
  transition: transform 150ms;
}
.slide-exit {
  transform: translateX(0);
}
.slide-exit-active {
  transform: translateX(-100%);
  transition: transform 150ms;
}

.slide-left-enter {
  transform: translateX(100%);
}
.slide-left-enter-active {
  transform: translateX(0);
  transition: transform 150ms;
}
.slide-left-exit {
  transform: translateX(0);
}
.slide-left-exit-active {
  transform: translateX(100%);
  transition: transform 150ms;
}

_::-webkit-full-page-media,
_:future,
:root .safariOpen {
  display: table-row;
}

_::-webkit-full-page-media,
_:future,
:root .safariClosed {
  display: none;
}

::-webkit-scrollbar {
  width: 0px; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}
